import React, { useEffect, useContext } from "react";
import { S3_ENDPOINT } from "../utils";
import { ModalContext } from "../context/ModalContext";
import PanelTitle from "../components/global/PanelTitle";
import RewardsLevels from "../components/rewards/RewardsLevels";
import { MilestonesContext } from "../context/MilestonesContext";
import { CustomerMilestonesContext } from "../context/CustomerMilestonesContext";
import SingleMilestoneBenefit from "../components/milestones/SingleMilestone";

const MisNiveles = () => {
  const { getCustomerMilestones } = useContext(
    CustomerMilestonesContext
  );
  const { getMilestones } = useContext(MilestonesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getCustomerMilestones();
    getMilestones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerShare = async () => {
    const url = `${S3_ENDPOINT}/customer-milestone-1.jpg`;

    const data = await fetch(url);
    const blob = await data.blob();

    const filesArray = [
      new File([blob], `milestone.jpg`, {
        type: "image/jpg",
        lastModified: new Date().getTime(),
      }),
    ];
    const shareData = {
      files: filesArray,
    };
    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    }
  };

  const showBenefit = (benefit) => {
    if (benefit.type === "video") {
      return modalComponent(
        benefit.name,
        <SingleMilestoneBenefit milestone_benefit={benefit} />
      );
    }
    window.open(benefit.src, "_blank");
  };

  return (
    <div className="container-fluid py-3 px-4">
      <PanelTitle title="Mis Niveles" onClick={triggerShare} buttonTitle="Share" />
      <RewardsLevels fluid />
    </div>
  );
};

export default MisNiveles;
