import React from "react";
import { Link } from "@reach/router";

const PanelNavbar = ({ user, signOut }) => {
  return (
    <nav
      id="panel-navbar"
      className="navbar navbar-expand-lg navbar-light shadow-sm border-bottom bg-light hide-mobile"
    >
      <div className="container-fluid">
        <Link
          to={window.location.pathname === "/mytbm" ? "/" : "/mytbm"}
          className="navbar-brand py-2 ms-3"
        >
          <img src="/img/logo.png" alt="logo" className="logo-navbar" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="clases"
              >
                Mis Clases
              </button>
              <ul className="dropdown-menu user-menu" aria-labelledby="clases">
                <li>
                  <Link to="./online" className="dropdown-item">
                    Online
                  </Link>
                </li>
                <li>
                  <Link to="./presencial" className="dropdown-item">
                    Presenciales
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="shop"
              >
                Comprar
              </button>
              <ul className="dropdown-menu user-menu" aria-labelledby="shop">
                <li>
                  <Link to="./paquetes" className="dropdown-item">
                    Paquetes
                  </Link>
                </li>
                <li>
                  <a
                    href="https://shop.thebodymethod.mx/"
                    className="dropdown-item"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Merch
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://tbmcertifications.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Certificación
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="comunidad"
              >
                Comunidad
              </button>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="comunidad"
              >
                <li>
                  <Link to="./comunidad" className="dropdown-item">
                    Chat
                  </Link>
                </li>
                <li>
                  <Link to="./leaderboards" className="dropdown-item">
                    Leaderboards
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="perfil"
              >
                {user.name} <i className="ms-2 fa fa-user-circle"></i>
              </button>
              <ul className="dropdown-menu user-menu" aria-labelledby="perfil">
                <li>
                  <Link to="./informacion" className="dropdown-item">
                    Mi Información
                  </Link>
                </li>
                <li>
                  <Link to="./tracks" className="dropdown-item">
                    Mi Progreso
                  </Link>
                </li>
                <li>
                  <Link to="./membresias" className="dropdown-item">
                    Mis Compras
                  </Link>
                </li>
                <li>
                  <Link to="./pagos" className="dropdown-item">
                    Mis Cargos
                  </Link>
                </li>
                <li>
                  <Link to="./metodos" className="dropdown-item">
                    Mis Métodos de Pago
                  </Link>
                </li>
                <li>
                  <Link to="./reservaciones" className="dropdown-item">
                    Mis Reservaciones
                  </Link>
                </li>
                {/* 
                <li>
                  <Link to="./rewards" className="dropdown-item">
                    TBM Rewards
                  </Link>
                </li> 
                */}
                <li>
                  <Link to="./notifications" className="dropdown-item">
                    Notificaciones
                  </Link>
                </li>
                <li className="mt-3">
                  <button
                    className="dropdown-item text-danger w-100"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
